<template>
    <div :class="thisClass ? thisClass : 'pt-3'">
        <v-menu offset-y>
            <template v-slot:activator="{ on: menu }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            height="36px"
                            small
                            v-on="{...tooltip, ...menu}"
                        >
                            <v-icon small dark v-on>ti ti-export</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ $t('message.export') }}</span>
                </v-tooltip>
            </template>
            <v-list dense>
                <v-list-item @click="exportTableToPdf()">
                    <v-list-item-title>{{ $t('message.exportAsPdf') }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportTableToExcel()">
                    <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
    import jsPDF from 'jspdf'
    import html2canvas from 'html2canvas'
    import XLSX from 'xlsx'
    import {delete_cols} from 'Helpers/excel'

    export default {
        name: "ExportTableOnly",
        props: ['thisClass','colsToDelete','fileName','tableId','hasAddFunction'],
        methods: {
            addFunction(){
                this.$emit('add-action')
            },
            exportTableToExcel() {
                const element = document.querySelector('#' + this.tableId + ' table')
                let ws = XLSX.utils.table_to_sheet(element)

                //remove some columns
                if(this.colsToDelete) {
                    let start_col = this.colsToDelete.start_col
                    let ncols = this.colsToDelete.ncols
                    delete_cols(ws,start_col,ncols)
                }

                //add some columns

                let wb = XLSX.utils.book_new()
                XLSX.utils.book_append_sheet(wb,ws)
                XLSX.writeFile(wb,this.fileName + '.xlsx')
            },
            exportTableToPdf() {
                let timeout = 2000
                const element = document.querySelector('#' + this.tableId + ' table'),
                    options = {
                        imageTimeout: timeout,
                        background: "white",
                        allowTaint : true,
                        useCORS: false,
                        height: element.clientHeight,
                        width: element.clientWidth
                    };

                html2canvas(element, options).then((canvas) => {
                    let imgData = canvas.toDataURL('image/png');

                    let imgWidth = 295,
                        pageHeight = 210,
                        imgHeight = canvas.height * imgWidth / canvas.width,
                        heightLeft = imgHeight,
                        doc = new jsPDF('l', 'mm'),
                        position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;

                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }
                    doc.save( this.fileName + '.pdf' );
                });
            }
        }
    }
</script>

<style>

</style>